import { useGetFlagQuery, useSetFlagMutation } from '@/graphql';

export const useStore = (key: string) => {
  const { result, refetch } = useGetFlagQuery({
    key,
  });

  const { mutate } = useSetFlagMutation({});

  const set = (value: boolean) => mutate({
    data: {
      key, value,
    },
  }).then(() => void refetch());

  return {
    set,
    value: computed(() => result.value?.storeGetFlag.value),
  };
};
