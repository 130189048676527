export const use2FaResetSuccess = () => {
  const router = useRouter();
  const confirmDialog = useConfirmDialog();

  const twoFactorResetSuccess = () => {
    confirmDialog({
      header: 'Authenticator successfully reset',
      message: 'For security, we recommend installing a new authenticator',
      icon: 'check-circle',
      group: '2fa-reset-success',
      color: ConfirmDialogColors.Green,
      acceptLabel: 'Setup now',
      rejectLabel: 'Continue without setup',
      accept: () => {
        void router.push({
          name: 'personal-settings',
          query: {
            authentication: 1,
          },
        });
      },
      reject: () => {},
    });
  };

  return {
    twoFactorResetSuccess,
  };
};
