import { object, string } from 'zod';

export const TwoFactorBackupCodeDTO = object({
  code: string(),
  confirm_code: string(),
  two_factor_type: string().optional(),
  phone: string().optional(),
  email: string().optional(),
  factor: string().optional(),
  identity: string().optional(),
});
