import { array, object, string } from 'zod';

export const TwoFactorPhoneDTO = object({
  phone: string().optional(),
  new_phone: string().optional(),
  phones: array(string()).default([]).optional(),
}).refine(
  ({ phone, phones }) => {
    if ((!phones && !phone) || (phones && phone)) return true;

    return false;
  },
  {
    path: ['phone'],
  },
).refine(
  ({ new_phone, phones }) => {
    if (!phones && !new_phone) return false;

    return true;
  },
  {
    path: ['new_phone'],
  },
);
