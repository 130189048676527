<template>
  <component
    :is="layout"
    :key="layout.name"
  />
</template>

<script setup lang="ts">
import { DefaultApolloClient, provideApolloClient } from '@vue/apollo-composable';
import { provide } from 'vue';

import { use2FaResetSuccess } from '@/modules/my-profile/use/dialogs/2fa-reset-success';
import { useStore } from '@/modules/store';

import { useShutdownSubscription } from './graphql';
import { apolloClient } from './util/graphql';

const TWOFACTOR_RESET_FLAG = '2fa-reset';

provide(DefaultApolloClient, apolloClient);
provideApolloClient(apolloClient);

const store = useStore(TWOFACTOR_RESET_FLAG);

const { onResult } = useShutdownSubscription();
const { twoFactorResetSuccess } = use2FaResetSuccess();

onResult(res => {
  if (res.data?.shutdown) {
    wsClient.terminate();
  }
});

watch(store.value, () => {
  if (store.value.value) {
    twoFactorResetSuccess();
    void store.set(false);
  }
});

const { component: layout } = useLayout();
</script>
