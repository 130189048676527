import { DecryptInput } from '@/graphql';
import { useDecrypt } from '@/use/decrypt';

export const useConfirmVerificationRequired = () => {
  const confirmDialog = useConfirmDialog();
  const route = useRoute();

  const { decrypt } = useDecrypt();

  const confirmInfo = {
    message: 'To access the sentest date, you need to re-validate and enter the password.',
    header: 'Verification Required',
    icon: 'passcode-lock',
    group: 'verification-required',
    color: ConfirmDialogColors.Primary,
    acceptLabel: 'Confirm',
    reject: () => {},
  };

  const confirm = (data: DecryptInput, callback: (value: string) => void) => {
    confirmDialog({
      ...confirmInfo,
      accept: async () => {
        const value = await decrypt({
          data,
        });

        callback(value);
      },
    });
  };

  const confirmWithoutDecrypt = (callback: () => void, target?: HTMLElement) => {
    let message = '';
    if (route.name === 'personal-settings') {
      message = 'To continue, first verify it’s you by entering your password';
    }

    const opts = {
      ...confirmInfo,
      accept: () => callback(),
      target,
    };

    if (message) {
      opts.message = message;
    }

    confirmDialog(opts);
  };

  return {
    confirm,
    confirmWithoutDecrypt,
  };
};
